
import Axios from "axios";
import { Component, Vue } from "vue-property-decorator";
import AxiosCancelToken from "@/helpers/AxiosCancelToken";

@Component({})
export default class App extends Vue {
  mounted() {
    //this.closePopover();
    Axios.interceptors.response.use(
      (response) => {
        // Do noting with response data
        if (response.data.error == false || response.status == 204) {
          this.showSuccessToast();
        } else if (response.data.error == true) {
          this.showErrorToast(response.data.errorMessages);
        }
        return response;
      },
      (error) => {
        if (!error.message.startsWith(AxiosCancelToken.AXIOS_CANCEL_ERROR_STRING)) {
          // Always print error at console, but not for cancel requests
          if (error.message.endsWith(404)) {
            this.$router.push("not-found").catch(() => {
              // it can happen that more 404 per page will be thrown. Catch them all!
            });
            return false;
          }

          if (!error._handled && Vue.config.errorHandler) {
            Vue.config.errorHandler(error, error, "info");
            error._handled = true;
          }

          // Show Toast it the user is logged in
          if (this.$auth.user()) {
            this.$toast.add({
              severity: "error",
              summary: "Error calling '" + error.config.method + "' on '" + error.config.url + "'",
              detail: error.message,
              life: 4000,
            });
          }
        } else {
          //console.log(error.message);
        }
        return false;
        // return Promise.reject(error);
      }
    );
  }

  /*  private closePopover() {
      document.getElementsByTagName("body")[0].addEventListener("click", (e) => {
        this.$root.$emit("bv::hide::popover");
      });
    }*/

  private showSuccessToast() {
    this.$toast.add({
      severity: "success",
      summary: this.$t("toast.success.title"),
      detail: this.$t("toast.success.message"),
      life: 4000,
    });
  }

  private showErrorToast(errorMessages: string[]) {
    let content = this.$t("toast.error.message").toString() + " ";
    if (errorMessages.length > 0) {
      content += errorMessages.join(", ");
    } else {
      content += this.$t("toast.error.unknown").toString();
    }
    this.$toast.add({
      severity: "error",
      summary: this.$t("toast.error.title"),
      detail: content,
      life: 4000,
    });
  }
}
