export type AlertFilterOptions = {
  severity: AlertSeverity[] | null;
  notNames: string[] | null;
};
export type AlertChangeStatus = {
  status: AlertStatusType;
  text: string;
  duration?: number | null;
};

export enum AlertStatusType {
  ack = "ack",
  shelve = "shelve",
  close = "close",
}

export enum AlertSeverity {
  critical = "critical",
  major = "major",
  minor = "minor",
  warning = "warning",
}
