import LocalStorage from "@/helpers/LocalStorage";
import { VNode } from "vue";
import { DirectiveBinding } from "vue/types/options";

export default class PermissionsHelper {
  public static hasPermission(user: any, permissionName: string): boolean | null {
    if (user.roles.includes("SUPER")) {
      //TODO check different
      // Yes, you can! ... its a super user!
      return true;
    }

    //TODO. remove null returning value if the new UserRights fully active
    const loggedInUser = localStorage.getItem("default_auth_user");
    if (loggedInUser !== null) {
      const permissions = JSON.parse(loggedInUser)["permissions"];
      return permissions.includes(permissionName);
    }

    return null;
  }

  public static getDebuggingText(value) {
    let debuggingTextForLocalDev = " ";
    if (PermissionsHelper.isDevEnvironment()) {
      debuggingTextForLocalDev = value;
    }
    return debuggingTextForLocalDev;
  }

  public static isDevEnvironment() {
    if (window.__env__ != undefined && window.__env__.NODE_ENV === "development") {
      return true;
    }
    return false;
  }

  public static replaceHTML(el: HTMLElement, binding: DirectiveBinding, vnode: VNode) {
    const debuggingTextForLocalDev = PermissionsHelper.getDebuggingText(binding.value);
    // the following code is copied by https://stackoverflow.com/questions/43003976/a-custom-directive-similar-to-v-if-in-vuejs
    // replace HTMLElement with comment node
    const comment = document.createComment(debuggingTextForLocalDev);
    Object.defineProperty(comment, "setAttribute", {
      value: () => undefined,
    });
    vnode.elm = comment;
    vnode.text = " ";
    vnode.isComment = true;
    vnode.context = undefined;
    vnode.tag = undefined;
    vnode.data!.directives = undefined;

    if (el.parentNode) {
      el.parentNode.replaceChild(comment, el);
    }
  }

  public static canViewElement(el: HTMLElement, binding: DirectiveBinding, vnode: VNode) {
    const permissionValue = PermissionsHelper.hasPermission(vnode.context?.$auth.user(), binding.value);
    if (permissionValue !== null) {
      if (!permissionValue) {
        PermissionsHelper.replaceHTML(el, binding, vnode);
      }
    }
  }

  public static canViewAtLeastOneElement(el: HTMLElement, binding: DirectiveBinding, vnode: VNode) {
    let replaceHtml = true;
    if (Array.isArray(binding.value)) {
      const permissionValue = PermissionsHelper.includesAtLeastOneElement(vnode.context?.$auth.user(), binding.value);
      if (permissionValue) {
        replaceHtml = false;
      }
    }
    if (replaceHtml) {
      PermissionsHelper.replaceHTML(el, binding, vnode);
    }
  }

  public static includesAtLeastOneElement(user: any, permissionsCollection: string[]) {
    let includesOne = false;
    for (const value of permissionsCollection) {
      const permissionValue = PermissionsHelper.hasPermission(user, value);
      if (permissionValue) {
        includesOne = true;
        return includesOne;
      }
    }
    return includesOne;
  }
}
