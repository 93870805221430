export default class Filter {
  public static id2Hex(value: any, upperCase = false, fillUpAndStrip = true) {
    if (value === null || value === undefined) {
      return "";
    }
    let hexId = Number(value).toString(16);
    if (fillUpAndStrip) {
      hexId = hexId.padStart(12, "0").substring(0, 8);
    }
    if (upperCase) {
      hexId = hexId.toUpperCase();
    }
    return hexId;
  }

  public static gatewayId2Hex(value: string | number | null | undefined) {
    return Filter.id2Hex(value, false, false);
  }
}
