
import { Component, Vue } from "vue-property-decorator";
import * as bootstrap from "bootstrap";
import {
  HomeIcon,
  UsersIcon,
  BarChart2Icon,
  LayersIcon,
  CircleIcon,
  SettingsIcon,
  StarIcon,
  SquareIcon,
  MessageCircleIcon,
  ChevronRightIcon,
} from "vue-feather-icons";
import PermissionsHelper from "@/helpers/Permissions";

@Component({
  components: {
    HomeIcon,
    UsersIcon,
    BarChart2Icon,
    LayersIcon,
    CircleIcon,
    SettingsIcon,
    StarIcon,
    SquareIcon,
    MessageCircleIcon,
    ChevronRightIcon,
  },
  props: {},
})
export default class Sidebar extends Vue {
  private customerCount: number | null = 0;
  private customerId: number | null = null;
  isCollapsed = true;

  created() {
    this.createToggle();
  }

  public mounted() {
    const keysOfCustomers = Object.keys(this.$auth.user().customers);
    this.customerCount = keysOfCustomers.length;
    this.customerId = this.customerCount > 0 ? Number(keysOfCustomers[0]) : null;
    if (PermissionsHelper.hasPermission(this.$auth.user(), "frontend.layout.user")) {
      //TODO: we need for the administration menu a better solution :)
      this.customerCount = null;
    }
  }

  createToggle() {
    document.addEventListener("DOMContentLoaded", function () {
      document.querySelectorAll(".sidebar .nav-link").forEach(function (element) {
        element.addEventListener("click", function (e) {
          const nextEl = element.nextElementSibling;
          const parentEl = element.parentElement;

          if (nextEl) {
            e.preventDefault();
            const mycollapse = new bootstrap.Collapse(nextEl);
            if (nextEl.classList.contains("show")) {
              mycollapse.hide();
            } else {
              mycollapse.show();
              if (parentEl?.parentElement != null) {
                const opened_submenu = parentEl.parentElement.querySelector(".submenu.show");
                if (opened_submenu) {
                  new bootstrap.Collapse(opened_submenu);
                }
              }
            }
          }
        });
      });
    });
  }

  toggleClass() {
    this.isCollapsed = !this.isCollapsed;
  }
}
