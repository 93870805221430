import { CancelTokenSource } from "axios";

export default class AxiosCancelToken {
  public static AXIOS_CANCEL_ERROR_STRING = "AxiosCancelToken: ";
  private source: CancelTokenSource;
  constructor(initialValue: CancelTokenSource) {
    this.source = initialValue;
  }

  getRequestCancelObject() {
    return { cancelToken: this.source.token };
  }

  async cancel(message: string | undefined = undefined) {
    if (message != undefined) {
      message = AxiosCancelToken.AXIOS_CANCEL_ERROR_STRING + message;
    }
    await this.source.cancel(message);
  }
}
