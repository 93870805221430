import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import store from "./store";
import { Component } from "vue-property-decorator";

// Setup Vue router
import VueRouter from "vue-router";
import router from "./router";

Vue.use(VueRouter);

// Setup Vue axios
import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);

import VilistoApiPlugin from "./plugins/VilistoApi";

Vue.use(VilistoApiPlugin, { axios: axios });

Component.registerHooks(["validations"]);

import i18n from "./i18n";

// Setup Vue auth
import VueAuth from "@d0whc3r/vue-auth-plugin/";
import auth from "./auth";
import { authInterception } from "./auth";

Vue.use(VueAuth, auth);
axios.interceptors.request.use(authInterception);

// Setup Primevue v2 & PrimeIcons for Vue2
import Primevue from "primevue/config";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";

Vue.use(Primevue);

// Setup Bootstrap 5
import "bootstrap";
import "bootstrap/scss/bootstrap.scss";

// Include Bootstrap vue components
import {
  SidebarPlugin,
  IconsPlugin,
  TablePlugin,
  TabsPlugin,
  CardPlugin,
  SpinnerPlugin,
  OverlayPlugin,
  DropdownPlugin,
  ButtonPlugin,
  ModalPlugin,
  TooltipPlugin,
  PaginationPlugin,
  FormInputPlugin,
  FormSelectPlugin,
  FormDatepickerPlugin,
  VBTogglePlugin,
  CollapsePlugin,
} from "bootstrap-vue";

Vue.use(SidebarPlugin);
Vue.use(IconsPlugin);
Vue.use(TablePlugin);
Vue.use(TabsPlugin);
Vue.use(CardPlugin);
Vue.use(SpinnerPlugin);
Vue.use(OverlayPlugin);
Vue.use(ButtonPlugin);
Vue.use(DropdownPlugin);
Vue.use(TooltipPlugin);
Vue.use(ModalPlugin);
Vue.use(PaginationPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormSelectPlugin);
Vue.use(FormDatepickerPlugin);
Vue.use(VBTogglePlugin);
Vue.use(CollapsePlugin);

import VueSmartWidget from "vue-smart-widget";
import SmartWidget from "vue-smart-widget";

Vue.use(VueSmartWidget);
Vue.use(SmartWidget);

import ToastService from "primevue/toastservice";

Vue.use(ToastService);
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue-multiselect-listbox/dist/vue-multi-select-listbox.css";

import VueScrollTo from "vue-scrollto";

Vue.use(VueScrollTo);

import Filter from "./filter";
// Add moment
import moment from "moment";

Vue.prototype.moment = moment;

// ToDo: Add file only for nice filters
// Add custom filter
Vue.filter("Id2Hex", function (value: number) {
  if (value == null) return "";
  return Filter.id2Hex(value, true);
});

Vue.filter("GatewayId2Hex", function (value: number) {
  if (value == null) return "";
  return Filter.gatewayId2Hex(value);
});

import { round } from "@/utils";

Vue.filter("round", round);

Vue.filter("dateFormat", function (value, format = "DD.MM.YYYY HH:mm:ss") {
  return moment(value).format(format);
});
Vue.filter("dateFormatDateOnly", function (value, format = "DD.MM.YYYY") {
  return moment(value).format(format);
});
Vue.filter("preview", function (value, cutOff = "30") {
  if (!value) {
    return "";
  }
  return value.substring(0, cutOff) + "...";
});

// Use Vuelidate plugin, components will need to register a hook Component.registerHooks(["validations"]) and define a validations method
import Vuelidate from "vuelidate";
// vuelidate and ts https://stackoverflow.com/questions/59722101/does-vuelidate-support-typescript-in-vuejs
Vue.use(Vuelidate as any);

//TODO: Vue 3 https://vuejs.org/guide/reusability/custom-directives.html#introduction
Vue.directive("can", (el, binding, vnode) => {
  PermissionsHelper.canViewElement(el, binding, vnode);
});
Vue.directive("collection", (el, binding, vnode) => {
  PermissionsHelper.canViewAtLeastOneElement(el, binding, vnode);
});

import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  BarElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";

ChartJS.register(Title, Tooltip, Legend, LineElement, LinearScale, CategoryScale, BarElement, PointElement);

// Additional flags
Vue.config.productionTip = false;

Vue.config.errorHandler = function (err, vm, info) {
  console.log("[Global Error Handler]: Error in " + info + ": " + err);
};

// Create primary vue instance
const app = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
  beforeCreate() {
    this.$store.commit("initialiseStore");
  },
});
app.$mount("#app");

import LocalStorage from "./helpers/LocalStorage";
import PermissionsHelper from "@/helpers/Permissions";

// change other locale
const savedLanguage = LocalStorage.get("language");
i18n.locale = savedLanguage == null ? "de" : savedLanguage;
