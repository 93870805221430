import { AxiosRequestConfig } from "axios";
import ShardedLogin from "./ShardedLogin";

export function authInterception(config: AxiosRequestConfig): AxiosRequestConfig {
  const token = localStorage.getItem("default_auth_token");

  // Override the baseUrl with the backend from the currently selected shard
  const baseUrl = ShardedLogin.getBackendUrl();
  if (baseUrl) {
    config.baseURL = baseUrl;
  } else {
    config.baseURL = ShardedLogin.getBackendDefaultUrlFromFrontendUrl();
  }

  if (token != null) {
    config.headers.Authorization = "Bearer " + token; // vue-auth-plugin removes the Bearer when it parses the token
  }

  return config;
}

/// Define and export config
const config = {
  authMeta: "auth",
  rolesVar: "roles",
  tokenDefaultName: "default_auth_token",
  userDefaultName: "default_auth_user",
  tokenStore: ["localStorage"], //['vuex', 'localStorage', 'cookie'],
  // Todo: https://supertokens.io/blog/cookies-vs-localstorage-for-sessions-everything-you-need-to-know
  headerTokenReplace: "{auth_token}",
  tokenType: "Bearer",
  vuexStoreSpace: "vue-auth",

  authRedirect: "/login",

  loginData: {
    url: "/auth/login",
    method: "POST",
    redirect: "/dashboard",
    headerToken: "Authorization",
    fetchUser: true,
  },
  registerData: {
    url: "/auth/register",
    method: "POST",
    redirect: "/user",
    headerToken: "Authorization",
    fetchUser: false,
  },
  logoutData: {
    url: "/auth/logout",
    method: "POST",
    redirect: "/",
    makeRequest: false,
  },
  fetchItem: "",
  fetchData: {
    url: "/auth/user/",
    method: "GET",
    interval: 30,
    enabled: true,
  },
  refreshData: {
    url: "/auth/refresh",
    method: "GET",
    interval: 30,
    enabled: false,
  },
};

export default config;
