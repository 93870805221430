
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class Sidebar extends Vue {
  public buildInfo = process.env.BUILD_VERSION + " - " + process.env.BUILD_DATE;
  public execInfo = "";

  mounted() {
    if (
      window.__env__ != null &&
      window.__env__.KUBERNETES_POD_NAME != "" &&
      window.__env__.KUBERNETES_POD_NAMESPACE != ""
    ) {
      this.execInfo = " @ " + window.__env__.KUBERNETES_POD_NAME + "." + window.__env__.KUBERNETES_POD_NAMESPACE;
    }
  }
}
