import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import Layout from "../views/layout/Layout.vue";
import HealthCheck from "../views/HealthCheck.vue";
import Home from "../views/Login.vue";
import PasswordReset from "../views/PasswordReset.vue";
import Logout from "../views/Logout.vue";
import PasswordForget from "../views/PasswordForget.vue";
import NotFound from "@/views/NotFound.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  /* Pages outside the internal area*/
  {
    path: "/",
    name: "startpage",
    component: Home,
  },
  {
    path: "/not-found",
    component: NotFound,
    meta: { layout: Layout, auth: true },
  },
  {
    path: "*",
    redirect: "/not-found",
  },
  {
    path: "/login",
    name: "login",
    component: Home,
  },
  {
    path: "/health",
    name: "health",
    component: HealthCheck,
  },
  {
    path: "/password-reset",
    name: "password_reset",
    component: PasswordReset,
  },
  {
    path: "/logout",
    component: Logout,
  },
  {
    path: "/password-forgot",
    component: PasswordForget,
  },
  /* Admin routes and pages */
  {
    path: "/admin/dashboard",
    name: "admin_dashboard",
    component: () => import(/* webpackChunkName: "user" */ "../views/admin/Dashboard.vue"),
    meta: { layout: Layout, auth: true },
  },
  {
    path: "/admin/users",
    name: "admin_users",
    component: () => import(/* webpackChunkName: "user" */ "../views/admin/Users.vue"),
    meta: { layout: Layout, auth: true },
  },
  {
    path: "/admin/user/:userId/settings",
    name: "user_settings",
    component: () => import(/* webpackChunkName: "user" */ "../views/shared/userSettings/UserSettings.vue"),
    meta: { layout: Layout, auth: true },
  },
  /* User routes and pages */
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import(/* webpackChunkName: "user" */ "../views/user/Dashboard.vue"),
    meta: { layout: Layout, auth: true },
  },
  {
    path: "/imprint",
    name: "imprint",
    component: () => import(/* webpackChunkName: "user" */ "../views/user/Imprint.vue"),
    meta: { layout: Layout },
  },
  {
    path: "/help",
    name: "help",
    component: () => import(/* webpackChunkName: "user" */ "../views/user/Help.vue"),
    meta: { layout: Layout },
  },
  {
    path: "/dev/tools",
    name: "dev",
    component: () => import(/* webpackChunkName: "user" */ "../views/dev/Tools.vue"),
    meta: { layout: Layout, auth: true },
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import(/* webpackChunkName: "user" */ "../views/user/Settings.vue"),
    meta: { layout: Layout, auth: true },
  },
  {
    path: "/customers",
    name: "customers",
    component: () => import(/* webpackChunkName: "user" */ "../views/user/Customers.vue"),
    meta: { layout: Layout, auth: true },
  },
  {
    path: "/customers/:customerId/settings",
    name: "customer_settings",
    component: () => import(/* webpackChunkName: "user" */ "../views/user/customerSettings/CustomerSettings.vue"),
    meta: { layout: Layout, auth: true },
  },
  {
    path: "/customers/:customer_id/thermostats",
    name: "customer_thermostats",
    component: () => import(/* webpackChunkName: "user" */ "../views/user/Thermostats.vue"),
    meta: { layout: Layout, auth: true },
  },
  {
    path: "/floorplan",
    name: "floorplan",
    component: () => import(/* webpackChunkName: "user" */ "../views/user/Floorplan.vue"),
    meta: { layout: Layout, auth: true },
  },
  {
    path: "/floorplan/customers/:customer_id/thermostats/:thermostat_id",
    name: "floorplan_thermostats",
    component: () => import(/* webpackChunkName: "user" */ "../views/user/Floorplan.vue"),
    meta: { layout: Layout, auth: true },
  },
  {
    path: "/thermostats",
    name: "thermostats",
    component: () => import(/* webpackChunkName: "user" */ "../views/user/Thermostats.vue"),
    meta: { layout: Layout, auth: true },
  },
  {
    path: "/gateways",
    name: "gateways",
    component: () => import(/* webpackChunkName: "user" */ "../views/user/Gateways.vue"),
    meta: { layout: Layout, auth: true },
  },
  {
    path: "/favorites",
    component: () => import(/* webpackChunkName: "user" */ "../views/user/Thermostats.vue"),
    meta: { layout: Layout, auth: true },
  },
  {
    path: "/customers/:customerId/thermostat/:thermostatId/settings",
    name: "thermostats_settings",
    component: () => import(/* webpackChunkName: "user" */ "../views/user/thermostatSettings/ThermostatSettings.vue"),
    meta: { layout: Layout, auth: true },
  },
  {
    path: "/customers/:customerId/thermostat/:thermostatId/graph",
    name: "thermostats_graph",
    component: () => import(/* webpackChunkName: "user" */ "../views/user/Thermostats.vue"),
    meta: { layout: Layout, auth: true },
  },
  {
    path: "/customers/:customer_id/hierarchy/:hierarchyId/thermostats",
    name: "hierarchy_thermostats",
    component: () => import(/* webpackChunkName: "user" */ "../views/user/Thermostats.vue"),
    meta: { layout: Layout, auth: true },
  },
  {
    path: "/customers/:customerId/hierarchy/:hierarchyId/settings",
    name: "hierarchy_settings",
    component: () => import(/* webpackChunkName: "user" */ "../views/user/hierarchySettings/HierarchySettings.vue"),
    meta: { layout: Layout, auth: true },
  },
  {
    path: "/customers/:customerId/thermostat/:thermostatId/occupancy",
    name: "thermostats_occupancy",
    component: () => import(/* webpackChunkName: "user" */ "../views/user/thermostatSettings/Occupancy.vue"),
    meta: { layout: Layout },
  },
  {
    path: "/analytics",
    name: "analytics",
    component: () => import(/* webpackChunkName: "user" */ "../views/user/Analytics.vue"),
    meta: { layout: Layout, auth: true },
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import(/* webpackChunkName: "user" */ "../views/user/Settings.vue"),
    meta: { layout: Layout, auth: true },
    //props: { userId:6 }
  },
  {
    path: "/faq",
    name: "faq",
    component: () => import(/* webpackChunkName: "user" */ "../views/user/FAQ.vue"),
    meta: { layout: Layout, auth: true },
    //props: { userId:6 }
  },
  {
    path: "/notification",
    name: "notification",
    component: () => import(/* webpackChunkName: "user" */ "../views/admin/Notification.vue"),
    meta: { layout: Layout, auth: true },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

(Vue as any).router = router;

export default router;
