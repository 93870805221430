<template>
  <dialog-prime-vue
    id="modal-change-password"
    ref="modal"
    @show="resetModal"
    @hide="resetModal"
    :visible.sync="display"
    :modal="true"
    :dismissableMask="true"
    class="small-dialog w-100"
    position="top"
    :baseZIndex="1055"
  >
    <template #header>
      <h5 class="m-0">{{ $t($options.name + ".title") }}</h5>
    </template>
    <form>
      <div class="form-group">
        <label for="recipient-name" class="col-form-label">{{ $t($options.name + ".current") }}:</label>
        <input
          v-model="$v.currentPassword.$model"
          :type="'password'"
          :class="{ 'is-invalid': $v.currentPassword.$error, 'form-control': true }"
        />
        <div class="invalid-feedback" v-if="hasError">{{ hasError }}</div>
      </div>
      <div class="mt-4 alert alert-info">
        {{
          $t("errorMessage.minPassword", [
            $t($options.name + ".new"),
            $v.newPassword.$params.minLength.min,
            specialChars,
          ])
        }}
      </div>
      <div class="form-group">
        <label for="recipient-name" class="col-form-label">{{ $t($options.name + ".new") }}:</label>
        <input
          v-model="$v.newPassword.$model"
          :type="'password'"
          :class="{ 'is-invalid': $v.newPassword.$error, 'form-control': true }"
        />
        <div class="error" v-if="!$v.newPassword.minLength">
          {{ $t("errorMessage.minLength", [$t($options.name + ".new"), $v.newPassword.$params.minLength.min]) }}
        </div>
        <div class="error" v-else-if="!$v.newPassword.containsUppercase">
          {{ $t("errorMessage.containsUppercase", [$t($options.name + ".new")]) }}
        </div>
        <div class="error" v-else-if="!$v.newPassword.containsLowercase">
          {{ $t("errorMessage.containsLowercase", [$t($options.name + ".new")]) }}
        </div>
        <div class="error" v-else-if="!$v.newPassword.containsNumber">
          {{ $t("errorMessage.containsNumber", [$t($options.name + ".new")]) }}
        </div>
        <div class="error" v-else-if="!$v.newPassword.containsSpecial">
          {{ $t("errorMessage.containsSpecial", [$t($options.name + ".new"), specialChars]) }}
        </div>
      </div>

      <div class="form-group">
        <label for="recipient-name" class="col-form-label">{{ $t($options.name + ".repeat") }}:</label>
        <input
          v-model="$v.repeatPassword.$model"
          :type="'password'"
          :class="{ 'is-invalid': $v.repeatPassword.$error, 'form-control': true }"
        />
        <div class="error" v-if="!$v.repeatPassword.sameAs">
          {{ $t("errorMessage.sameAs", [$t($options.name + ".new"), $t($options.name + ".repeat")]) }}
        </div>
      </div>
    </form>
    <template #footer>
      <button class="btn btn-secondary" @click="display = false">
        {{ $t("thermostatSettings.general.modal.deleteThermostat.cancelButton") }}
      </button>
      <button class="btn btn-primary" @click="handleOk">Ok</button>
    </template>
  </dialog-prime-vue>
</template>

<script>
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import DialogPrimeVue from "primevue/dialog/Dialog";

export default {
  name: "modal.changePassword",
  components: { DialogPrimeVue },
  data: () => ({
    currentPassword: "",
    newPassword: "",
    repeatPassword: "",
    hasError: "",
    specialChars: "#?!@$%^&*-",
    display: false,
  }),
  validations: {
    currentPassword: { required, minLength: minLength(5) },
    newPassword: {
      required,
      minLength: minLength(8),
      containsUppercase: function (value) {
        return /[A-Z]/.test(value);
      },
      containsLowercase: function (value) {
        return /[a-z]/.test(value);
      },
      containsNumber: function (value) {
        return /[0-9]/.test(value);
      },
      containsSpecial: function (value) {
        return /[#?!@$%^&*-]/.test(value);
      },
    },
    repeatPassword: {
      required,
      minLength: minLength(8),
      sameAsNewPassword: sameAs("newPassword"),
    },
  },
  methods: {
    show() {
      this.display = true;
    },
    resetModal(bvModalEvt) {
      this.currentPassword = "";
      this.newPassword = "";
      this.repeatPassword = "";
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      this.$v.$touch();
      // Exit when the form isn't valid
      if (this.$v.$invalid) {
        return;
      }

      this.$http({
        url: "/auth/change-password",
        method: "POST",
        data: { password: this.currentPassword, newPassword: this.newPassword },
      }).then(
        (res) => {
          console.log(res);
          this.$nextTick(() => {
            this.display = false;
          });
        },
        (res) => {
          if (res.response.status == 400) {
            this.hasError = "Ungültiges Password";
            this.currentPassword = "";
            this.newPassword = "";
            this.repeatPassword = "";
          } else {
            console.log(res.response);
          }
        }
      );
    },
  },
};
</script>
