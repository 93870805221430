export enum FloorplanDeviceType {
  Thermostat = "thermostat",
  Gateway = "gateway",
}

export enum FloorplanMode {
  Temperature = "temperature",
  Updates = "time",
  SetTemp = "setTemp",
  Battery = "battery",
  RSSI = "rssi",
  TemperatureDelta = "temperatureDelta",
  Firmware = "embeddedVersion",
  Gateway = "gatewayId",
}

export interface HierarchyElement {
  id: string;
  type: "customer" | "node" | FloorplanDeviceType;
  children: HierarchyElement[] | undefined;
  customerId: number;
  operation: {
    address: string | null;
    floorplan_image_url: string | null;
    floorplan_x: number | null;
    floorplan_y: number | null;
    floorplan_scaling: number | null;
    name: string | null;
    notes: string | null;
  };
}

export interface NamedFloorplanMode {
  name: string;
  mode: FloorplanMode;
}

export interface DragElementData {
  name: string | null;
  id: string;
  type: FloorplanDeviceType;
}

export const floorplanModesSuper = [
  FloorplanMode.Temperature,
  FloorplanMode.TemperatureDelta,
  FloorplanMode.SetTemp,
  FloorplanMode.Updates,
  //FloorplanMode.Battery,
  //FloorplanMode.RSSI,
  FloorplanMode.Firmware,
  FloorplanMode.Gateway,
];
export const floorplanModes = [FloorplanMode.Temperature, FloorplanMode.Updates, FloorplanMode.SetTemp];

export interface FloorplanDevice {
  id: string;
  name: string | null;
  type: FloorplanDeviceType;
  position:
    | {
        x: number;
        y: number;
      }
    | undefined;
  lastUpdates: {
    embeddedVersion?: string | null;
    sysMode?: number | null;
    temperature?: number | null;
    temperatureDelta?: number | null;
    time?: number | null;
    setTemp?: number | null;
    batteryVoltage?: number | null;
    rssi?: number | null;
    windowOpenDetection?: number | null;
    gatewayId?: number | null;
  };
}

export type PositionChangeEvent = Pick<FloorplanDevice, "id" | "type" | "position">;

export interface SelectEvent {
  id: string;
  type: FloorplanDeviceType;
}

export enum OnlineStatus {
  Online = "Online",
  Absent = "Absent",
  Offline = "Offline",
}
