import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    notificationCount: null,
    globalObjectSelection: { customerId: null, objectId: null },
  },
  mutations: {
    switchUserRole(state, role) {
      // mutate state
      state["vue-auth"].roles = [role];

      const userAuth = localStorage.getItem("default_auth_user");
      if (userAuth != null) {
        const userAuthJson = JSON.parse(userAuth);
        if (role == "SUPER") {
          delete userAuthJson["switchUserRole"];
        } else {
          userAuthJson["switchUserRole"] = true;
        }
        userAuthJson["roles"] = [role];
        localStorage.setItem("default_auth_user", JSON.stringify(userAuthJson));
        location.reload();
      }
    },
    notificationCount(state, count) {
      state.notificationCount = count;
    },
    globalObjectSelection(state, ids) {
      localStorage.setItem("globalObjectSelection", JSON.stringify(ids));
      state.globalObjectSelection = ids;
    },
    initialiseStore(state) {
      const globalObjectSelection = localStorage.getItem("globalObjectSelection");
      if (globalObjectSelection !== null) {
        state.globalObjectSelection = JSON.parse(globalObjectSelection);
        console.log(state.globalObjectSelection);
      }
    },
  },
  actions: {},
  modules: {},
  getters: {
    getGlobalObjectSelection: (state) => () => state.globalObjectSelection,
  },
});

(Vue as any).store = store;

export default store;
