
import { Component, Vue } from "vue-property-decorator";
import ShardedLogin from "@/auth/ShardedLogin";

@Component
export default class Login extends Vue {
  private username = "";
  private password = "";
  private showErrorMessage = false;
  public submit(): void {
    // Wrap the actual login into the ShardedLogin logic
    ShardedLogin.updateValidLoginResults(this.username, this.password).then((result) => {
      if (result.length >= 1) {
        this.showErrorMessage = false;
        // Always use result url, since shard url is outdated
        ShardedLogin.setBackendUrl(result[0].url);
        // Use the actual auth plugin
        this.$auth
          .login({
            email: this.username,
            password: this.password,
          })
          .then((result) => {
            this.showErrorMessage = false;
            //console.log('(2) Inside result:', result)
          })
          .catch((err) => {
            this.showErrorMessage = true;
            console.log(JSON.stringify(err));
          });
      } else {
        this.showErrorMessage = true;
      }
    });
  }
}
