
import { Component, Vue } from "vue-property-decorator";
import Navbar from "./Navbar.vue";
import Sidebar from "./Sidebar.vue";
import Footer from "./Footer.vue";
import ScrollTop from "primevue/scrolltop";
import Toast from "primevue/toast";

@Component({
  components: {
    Toast,
    Navbar,
    Sidebar,
    Footer,
    ScrollTop,
  },
})
export default class Layout extends Vue {}
