import { OnlineStatus } from "@/components/floorplan/types";
import moment from "moment/moment";

export function round(value: number, decimals = 2) {
  if (value == undefined || isNaN(value)) {
    return null;
  }
  return Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
}

export function roundedPercentage(absolut: number) {
  return round(absolut * 100, 0);
}

export function convertIntegerToBitSum(num: number): string {
  const result: string[] = [];
  for (let i = 0; i < 12; i++) {
    if ((num & (1 << i)) !== 0) {
      result.push((1 << i).toString());
    }
  }
  return result.join(",");
}

export function formatSecToDHMS(value: number) {
  value = Number(value);
  const d = Math.floor(value / (3600 * 24));
  const h = Math.floor((value % (3600 * 24)) / 3600);
  const m = Math.floor((value % 3600) / 60);
  const s = Math.floor(value % 60);

  const dShow = d > 0 ? d + "d " : "";
  const hShow = h > 0 ? h + "h " : "";
  const mShow = m > 0 ? m + "m " : "";
  const sShow = s > 0 ? s + "s " : "";
  return dShow + hShow + mShow + sShow;
}

export function itemFinder<T extends { children: T[] | undefined; id: string }>(arr: T[], id: T["id"]): T | undefined {
  if (arr.length === 0) return undefined;

  const child = arr.find((item) => item.id === id);
  if (child) {
    return child;
  }

  return itemFinder(
    arr.flatMap((item) => item.children ?? []),
    id
  );
}

export class Utils {
  static offline = 24 * 60; // 24 hours
  static absent = 15; // minutes
  static online = 0;

  static getStatus(time: number | null | undefined) {
    const getThermostatsMoment = moment(Date.now());
    if (time == null) {
      return OnlineStatus.Offline;
    } else {
      return this.statusFromTimespan(getThermostatsMoment.diff(time, "minutes"));
    }
  }

  static statusFromTimespan(minutes: number) {
    if (minutes > this.offline) {
      return OnlineStatus.Offline;
    } else if (minutes > this.absent) {
      return OnlineStatus.Absent;
    } else {
      return OnlineStatus.Online;
    }
  }
}
