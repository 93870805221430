
import { required, minLength, sameAs, email } from "vuelidate/lib/validators";
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "PasswordReset",
  validations: {
    newPassword: {
      required,
      minLength: minLength(8),
      containsUppercase: function (value) {
        return /[A-Z]/.test(value);
      },
      containsLowercase: function (value) {
        return /[a-z]/.test(value);
      },
      containsNumber: function (value) {
        return /[0-9]/.test(value);
      },
      containsSpecial: function (value) {
        return /[#?!@$%^&*-]/.test(value);
      },
    },
    repeatPassword: {
      required,
      minLength: minLength(8),
      sameAsNewPassword: sameAs("newPassword"),
    },
    email: {
      required,
      email,
    },
  },
})
export default class PasswordReset extends Vue {
  private password = "";
  private email = this.$route.query.email;
  private newPassword = "";
  private repeatPassword = "";
  private showErrorMessage = false;

  specialChars = "#?!@$%^&*-";

  public submit(): void {
    this.$v.$touch();
    // Exit when the form isn't valid
    if (this.$v.$invalid) {
      return;
    }
    this.$http({
      url: "/auth/password-reset",
      method: "POST",
      data: {
        password: this.newPassword,
        token: this.$route.query.token,
        email: this.email,
      },
    })
      .then((res) => {
        this.$router.push("login");
      })
      .catch((err) => {
        // console.log(JSON.stringify(err));
        //if (res.response.status == 400) {
        this.newPassword = "";
        this.repeatPassword = "";
        this.showErrorMessage = true;
      });
  }
}
