import VilistoApi from "./lib/vilisto-api";
import _Vue from "vue";
import { AxiosStatic } from "axios";
const VilistoApiPlugin = {
  install: (Vue: typeof _Vue, options: { axios: AxiosStatic }) => {
    const vilistoApiComponent = new VilistoApi(options.axios);
    Vue.mixin({
      created() {
        Vue.prototype.$vilistoApi = vilistoApiComponent;
      },
    });
  },
};

export default VilistoApiPlugin;
