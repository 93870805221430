export default class LocalStorage {
  public static save(name: string, value: any) {
    localStorage.setItem(name, value);
  }

  public static get(name: string) {
    return localStorage.getItem(name);
  }

  public static getLocalStorageValue(name: string) {
    return localStorage.getItem(name) === "true";
  }

  public static clear() {
    localStorage.clear();
  }
}
