
import { Component, Vue } from "vue-property-decorator";
import {
  AtSignIcon,
  RefreshCcwIcon,
  AlertCircleIcon,
  AlignJustifyIcon,
  CalendarIcon,
  MailIcon,
  MessageCircleIcon,
  UserIcon,
  SettingsIcon,
  KeyIcon,
  HelpCircleIcon,
} from "vue-feather-icons";

import ChangePassword from "@/modal/ChangePassword.vue";
import { AlertSeverity } from "@/plugins/VilistoApi/types/alert-settings";
import LocalStorage from "@/helpers/LocalStorage";
import ShardedLogin from "@/auth/ShardedLogin";
@Component({
  components: {
    AlignJustifyIcon,
    AlertCircleIcon,
    AtSignIcon,
    CalendarIcon,
    MailIcon,
    MessageCircleIcon,
    UserIcon,
    SettingsIcon,
    RefreshCcwIcon,
    KeyIcon,
    HelpCircleIcon,
    ChangePassword,
  },
})
export default class Navbar extends Vue {
  private notifications: any[] = [];
  private userRoles: string[] = [];
  private notificationsRealLength = 0;
  private readonly maxNotficationCount = 99;
  private currentBackendUrl: string = ShardedLogin.getBackendUrl();

  getNotifications() {
    this.$vilistoApi
      .getAlerts({
        notNames: null,
        severity: [AlertSeverity.critical, AlertSeverity.major, AlertSeverity.minor],
      })
      .then((response) => {
        this.notificationsRealLength = response.data.alerts.length;
        const shownLength = this.notificationsRealLength > 3 ? 3 : this.notificationsRealLength;

        for (let i = 0; i < shownLength; i++) {
          // show only 3 notifications here
          const notificationData = response.data.alerts[i];
          this.notifications.push({
            id: i,
            title: notificationData.name,
            desc: this.$t("admin.notification.customer") + ": " + notificationData.customerId,
            time: notificationData.createTime,
            status: notificationData.severity,
          });
        }
        // $store "notificationCount" Also used by Sidebar and Notification component
        this.$store.commit("notificationCount", this.notificationsRealLength);
      })
      .catch((e) => {
        console.log(e);
      });
  }
  saveLanguage(chosenLanguage) {
    LocalStorage.save("language", chosenLanguage);
    this.$i18n.locale = chosenLanguage;
  }

  validBackendLogins() {
    return ShardedLogin.getStoredValidLoginResults();
  }

  setCurrentBackendUrl(url: string) {
    this.currentBackendUrl = url;
    ShardedLogin.setBackendUrl(url);
    if (this.$router.currentRoute.name != "dashboard") {
      this.$router.push({ name: "dashboard" });
    }
    this.$forceUpdate(); // Make sure the user roles and alerts are reloaded
  }

  formatBackendUrl(url: string): string {
    // strip the leading http(s):// and the trailing /api/
    return url.replace(/^https?:\/\//, "").replace(/\/api\/$/, "");
  }

  get viewableNotificationCount() {
    if (!this.$store.state.notificationCount) {
      return "";
    } else if (this.$store.state.notificationCount > this.maxNotficationCount) {
      return this.maxNotficationCount + "+";
    } else {
      return this.$store.state.notificationCount.toString();
    }
  }

  showChangePassword() {
    (this.$refs["change-password"] as any).show();
  }

  mounted() {
    //this.getNotifications();
  }
}
