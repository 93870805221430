
import { required, email } from "vuelidate/lib/validators";
import { Component, Vue } from "vue-property-decorator";

@Component({
  validations: {
    email: {
      required,
      email,
    },
  },
})
export default class ResetPassword extends Vue {
  private show = true;
  public email = "";
  private showErrorMessage = false;

  public submit(): void {
    this.$v.$touch();
    // Exit when the form isn't valid
    if (this.$v.$invalid) {
      return;
    }
    this.$http({
      url: "/auth/" + this.email + "/password-reset",
      method: "GET",
    })
      .then((res) => {
        this.show = false;
        this.showErrorMessage = false;
      })
      .catch((err) => {
        console.log(JSON.stringify(err));
        this.showErrorMessage = true;
        //if (res.response.status == 400) {
      });
  }
}
