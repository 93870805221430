import { AlertChangeStatus, AlertFilterOptions } from "../types/alert-settings";
import { GraphOptions } from "../types/graph";
import { ObjectBreadcrumbOptions, ObjectSettingsOptions, ObjectSettingsSaving } from "../types/object-settings";
import { CustomerListOptions } from "@/plugins/VilistoApi/types/customer";
import { AxiosRequestConfig } from "axios";
import LocalStorage from "@/helpers/LocalStorage";
import { UserDataSavingAccessEntity } from "@/plugins/VilistoApi/types/user-access";

export default class VilistoApi {
  $http: any; //TODO: add Type AxiosStatic

  constructor(axios) {
    //TODO: remove it, if the new userRights will be used as a standard
    if (LocalStorage.getLocalStorageValue("useNewUserRights")) {
      axios.defaults.headers.common["vilisto-user-rights"] = LocalStorage.getLocalStorageValue("useNewUserRights");
      axios.defaults.headers.common["vilisto-permissions"] = localStorage.getItem("new_permissions");
    }
    this.$http = axios;
  }

  getUserDataAccess(userId: number, configs: AxiosRequestConfig = {}) {
    return this.$http.get("/internal/dev/users/" + userId + "/data-access", configs);
  }

  saveUserDataAccess(userId: number, data: UserDataSavingAccessEntity[], configs: AxiosRequestConfig = {}) {
    return this.$http.post("/internal/dev/users/" + userId + "/data-access", data, configs);
  }

  deleteUserDataAccess(userId: number, data: UserDataSavingAccessEntity[], configs: AxiosRequestConfig = {}) {
    return this.$http.post("/internal/dev/users/" + userId + "/data-access/delete", data, configs);
  }

  getThermostats(
    customerId: any,
    hierarchyId: any = null,
    options: {
      lastUpdates: boolean;
      operation: boolean;
      configuration: boolean;
      connections: boolean;
    } = {
      lastUpdates: true,
      operation: true,
      configuration: true,
      connections: true,
    },
    configs: AxiosRequestConfig = {}
  ) {
    const extendedOptionsUrl = VilistoApi.buildQueryParametersString(options);

    if (customerId == undefined) {
      return this.$http.get("/thermostats" + extendedOptionsUrl, configs);
    } else if (customerId !== null && hierarchyId === null) {
      return this.$http.get("/customers/" + customerId + "/thermostats" + extendedOptionsUrl, configs);
    } else if (customerId !== null && hierarchyId !== null) {
      return this.$http.get(
        "/customers/" + customerId + "/objects/" + hierarchyId + "/thermostats" + extendedOptionsUrl,
        configs
      );
    }
  }

  getThermostatGraph(customerId: any, objectId: any, data: GraphOptions, configs: AxiosRequestConfig = {}) {
    return this.$http.post("/customers/" + customerId + "/thermostats/" + objectId + "/graph", data, configs);
  }

  getThermostatEvents(customerId: any, objectId: any, data: GraphOptions, configs: AxiosRequestConfig = {}) {
    return this.$http.post("/customers/" + customerId + "/thermostats/" + objectId + "/events", data, configs);
  }

  getThermostatGraphValvePosition(
    customerId: any,
    objectId: any,
    data: GraphOptions,
    configs: AxiosRequestConfig = {}
  ) {
    return this.$http.post("/customers/" + customerId + "/thermostats/" + objectId + "/valveposition", data, configs);
  }

  getCustomers(options: CustomerListOptions = {}, configs: AxiosRequestConfig = {}) {
    let queryParameters = options.loadAlerts === true ? "&loadAlerts=true" : "";
    if (queryParameters != "") {
      queryParameters = "?" + queryParameters.substring(1);
    }
    return this.$http.get("/customers" + queryParameters, configs);
  }

  getObjectsHierarchy(
    customerId: string | number,
    objectId: string | number,
    options: {
      thermostats?: boolean;
      configuration?: boolean;
      operation?: boolean;
    } = {
      thermostats: false,
      configuration: false,
      operation: false,
    },
    configs: AxiosRequestConfig = {}
  ) {
    if (this.getObjectBasicUrl(customerId, objectId) != null) {
      const extendedOptionsUrl = VilistoApi.buildQueryParametersString(options);

      return this.$http.get(this.getObjectBasicUrl(customerId, objectId) + "/hierarchy" + extendedOptionsUrl, configs);
    }
  }

  getHierarchy(
    options: {
      thermostats?: boolean;
      configuration?: boolean;
      operation?: boolean;
    } = {
      thermostats: false,
      configuration: false,
      operation: false,
    },
    configs: AxiosRequestConfig = {}
  ) {
    const extendedOptionsUrl = VilistoApi.buildQueryParametersString(options);

    return this.$http.get("/internal/dev/hierarchy" + extendedOptionsUrl, configs);
  }

  getFloorplanImage(customerId: any, objectId: any) {
    if (this.getObjectBasicUrl(customerId, objectId) != null) {
      return this.$http.get(this.getObjectBasicUrl(customerId, objectId) + "/floorplan-image");
    }
  }

  getCurrentDailyOfObject(customerId: number | null, objectId: number | null, limit: number | null) {
    if (this.getObjectBasicUrl(customerId, objectId) != null) {
      let queryParameters = limit ? `&limit=${limit}` : "";
      if (queryParameters != "") {
        queryParameters = "?" + queryParameters.substring(1);
      }

      return this.$http.get(this.getObjectBasicUrl(customerId, objectId) + "/current-daily" + queryParameters);
    }
  }

  getCurrent(customerId: number | null, objectId: number | null) {
    if (this.getObjectBasicUrl(customerId, objectId) != null) {
      return this.$http.get(this.getObjectBasicUrl(customerId, objectId) + "/current");
    }
  }

  getObjectSettings(
    customerId: any,
    objectId: any,
    options: ObjectSettingsOptions = {},
    configs: AxiosRequestConfig = {}
  ) {
    if (this.getObjectBasicUrl(customerId, objectId) != null) {
      let queryParameters = options.flatten === false ? "&flatten=false" : "";
      queryParameters += options.operation === false ? "&operation=false" : "";
      queryParameters += options.configuration === false ? "&configuration=false" : "";
      queryParameters += options.details === false ? "&details=false" : "";
      queryParameters += options.filter != null ? "&filter=" + options.filter : "";

      if (queryParameters != "") {
        queryParameters = "?" + queryParameters.substring(1);
      }

      return this.$http.get(this.getObjectBasicUrl(customerId, objectId) + "/settings" + queryParameters, configs);
    }
  }

  getObjectOverrides(customerId: any, objectId: any) {
    const httpCall = () => {
      return this.$http.get(this.getObjectBasicUrl(customerId, objectId) + "/settings/overrides");
    };
    return this.callObjectOverrides(customerId, objectId, httpCall);
  }

  addObjectOverride(customerId: any, objectId: any, body: object) {
    const httpCall = () => {
      return this.$http.post(this.getObjectBasicUrl(customerId, objectId) + "/settings/overrides", body);
    };
    return this.callObjectOverrides(customerId, objectId, httpCall);
  }

  changeObjectOverride(customerId: any, objectId: any, overrideId: any, valueObject: object) {
    const httpCall = () => {
      return this.$http.put(
        this.getObjectBasicUrl(customerId, objectId) + "/settings/overrides/" + overrideId,
        valueObject
      );
    };
    return this.callObjectOverrides(customerId, objectId, httpCall);
  }

  deleteObjectOverride(customerId: any, objectId: any, overrideId: any) {
    const httpCall = () => {
      return this.$http.delete(this.getObjectBasicUrl(customerId, objectId) + "/settings/overrides/" + overrideId);
    };
    return this.callObjectOverrides(customerId, objectId, httpCall);
  }

  private callObjectOverrides(customerId: any, objectId: any, httpCall) {
    if (objectId == null) {
      objectId = customerId;
    }
    if (this.getObjectBasicUrl(customerId, objectId) == null) {
      return null;
    }
    return httpCall();
  }

  getObjectParents(customerId: any, objectId: any, options: ObjectBreadcrumbOptions = {}) {
    if (this.getObjectBasicUrl(customerId, objectId) != null) {
      let queryParameters = options.details === true ? "&details=true" : "";
      if (queryParameters != "") {
        queryParameters = "?" + queryParameters.substring(1);
      }
      return this.$http.get(this.getObjectBasicUrl(customerId, objectId) + "/parents" + queryParameters);
    }
  }

  getDataProtection(customerId: number | string) {
    // in the moment it is only implemented for the whole customer
    return this.$http.get(
      "/internal/dev/customers/" + customerId + "/objects/" + customerId + "/settings/data-protection"
    );
  }

  getObjectAlerts(customerId: any, objectId: any, options: ObjectSettingsOptions = {}) {
    if (objectId === null) {
      objectId = customerId;
    }
    if (this.getObjectBasicUrl(customerId, objectId) != null) {
      return this.$http.get(this.getObjectBasicUrl(customerId, objectId) + "/alerts");
    }
  }

  getAlerts(filterOptions: AlertFilterOptions | null = null) {
    let queryParameters = "";
    if (filterOptions?.severity != null && filterOptions?.severity.length > 0) {
      queryParameters += "&severity=" + filterOptions?.severity.join(",");
    }
    if (filterOptions?.notNames != null && filterOptions?.notNames.length > 0) {
      queryParameters += "&notNames=" + filterOptions?.notNames.join(",");
    } else if (localStorage["notNames"] != null) {
      queryParameters += "&notNames=" + localStorage["notNames"];
    }
    if (queryParameters != "") {
      queryParameters = "?" + queryParameters.substring(1);
    }
    return this.$http.get("/alerts" + queryParameters);
  }

  getAlertDetails(alertId: string) {
    return this.$http.get("/alerts/" + alertId);
  }

  changeStatusOfAlert(alertId: string, data: AlertChangeStatus) {
    return this.$http.put("/alerts/" + alertId + "/status", data);
  }

  changeStatusOfMultipleAlerts(data: AlertChangeStatus & { alertIds: string[] }) {
    return this.$http.put("/alerts/status", data);
  }

  saveObjectSettings(customerId: any, objectId: any, sendData: ObjectSettingsSaving) {
    if (this.getObjectBasicUrl(customerId, objectId) != null) {
      return this.$http.post(this.getObjectBasicUrl(customerId, objectId) + "/settings", sendData);
    }
  }

  deleteObjectSettings(customerId: any, objectId: any, service: string, settingsName: string) {
    if (this.getObjectBasicUrl(customerId, objectId) != null) {
      return this.$http.delete(
        this.getObjectBasicUrl(customerId, objectId) + "/settings/" + service + "/" + settingsName
      );
    }
  }

  saveLatitudeLongitude(customerId: any, objectId: any, body: { config: { latitude: number; longitude: number } }) {
    if (this.getObjectBasicUrl(customerId, objectId) != null) {
      return this.$http.post(this.getObjectBasicUrl(customerId, objectId) + "/location", body);
    }
  }

  getLatLongFromAddress(
    customerId: any,
    objectId: any,
    body: {
      street: string;
      streetNumber: string;
      postcode: number;
      city: string;
    }
  ) {
    if (this.getObjectBasicUrl(customerId, objectId) != null) {
      return this.$http.post(this.getObjectBasicUrl(customerId, objectId) + "/address", body);
    }
  }

  saveFloorplanImage(customerId: any, objectId: any, image: [string]) {
    if (this.getObjectBasicUrl(customerId, objectId) != null) {
      return this.$http.post(this.getObjectBasicUrl(customerId, objectId) + "/floorplan-image", image);
    }
  }

  changeThermostatOnFloorplan(customerId: any, objectId: any, body: any) {
    if (this.getObjectBasicUrl(customerId, objectId) != null) {
      return this.$http.put(this.getObjectBasicUrl(customerId, objectId) + "/floorplan", body);
    }
  }

  addNode(customerId: any, objectId: any, sendData: ObjectSettingsSaving) {
    if (this.getObjectBasicUrl(customerId, objectId) != null) {
      return this.$http.post(this.getObjectBasicUrl(customerId, objectId) + "/node", sendData);
    }
  }

  deleteNode(customerId: any, objectId: any) {
    if (this.getObjectBasicUrl(customerId, objectId) != null) {
      return this.$http.delete(this.getObjectBasicUrl(customerId, objectId) + "/node");
    }
  }

  deleteObject(customerId: any, objectId: any) {
    if (this.getObjectBasicUrl(customerId, objectId) != null) {
      return this.$http.delete(this.getObjectBasicUrl(customerId, objectId) + "");
    }
  }

  // TODO rename to name without put and find a more meaningful name, maybe to 'changeParent'
  putObject(customerId: any, objectId: any, parent: any) {
    if (this.getObjectBasicUrl(customerId, objectId) != null) {
      return this.$http.put(this.getObjectBasicUrl(customerId, objectId) + "/parent", parent);
    }
  }

  getThermostatsValveMovement(customerId: any, objectId: any) {
    if (this.getObjectBasicUrl(customerId, objectId) != null) {
      return this.$http.get(this.getObjectBasicUrl(customerId, objectId) + "/battery/thermostats");
    }
  }

  getControlSupervisorTable(customerId: any, objectId: any, startDate: string) {
    if (this.getObjectBasicUrl(customerId, objectId) != null) {
      return this.$http.get(
        this.getObjectBasicUrl(customerId, objectId) + "/controlSupervisor/pi-tuning-parameters?startDate=" + startDate
      );
    }
  }
  getDailyAggTable(customerId: any, objectId: any, startDate: any, numToShow: any) {
    if (this.getObjectBasicUrl(customerId, objectId) != null) {
      return this.$http.get(
        this.getObjectBasicUrl(customerId, objectId) +
          "/controlSupervisor/daily-aggregations?startDate=" +
          startDate +
          "&numToShow=" +
          numToShow
      );
    }
  }

  getValveMovementHistogramData(customerId: any, objectId: any) {
    if (this.getObjectBasicUrl(customerId, objectId) != null) {
      return this.$http.get(this.getObjectBasicUrl(customerId, objectId) + "/battery/valveMovementHistogram");
    }
  }

  getOccupancy(customerId: any, objectId: any) {
    const basicUrl = this.getObjectBasicUrl(customerId, objectId);
    if (basicUrl != null) {
      return this.$http.get(basicUrl + "/occupancy");
    }
  }

  getOccupancyDetectionModels() {
    // Returns the names of the detection models that can be used for training
    // (not to be confused with the thermostat specific prediction models)
    return this.$http.get("/occupancy/detectionModels");
  }

  getOccupancyAggregation(customerId: any, objectId: any, query: string) {
    // typical query is of the form mainModel=true | modelName=abc | detectionModel=def
    const basicUrl = this.getObjectBasicUrl(customerId, objectId);
    if (basicUrl != null) {
      return this.$http.get(basicUrl + "/occupancy/aggregate?" + query);
    }
  }

  getThermostatBatteryData(customerId: any, thermostatId: any) {
    if (customerId !== null && thermostatId !== null) {
      return this.$http.get("/customers/" + customerId + "/thermostats/" + thermostatId + "/battery/thermostatTab");
    }
  }

  getThermostatSensorData(customerId: any, thermostatId: any) {
    if (customerId !== null && thermostatId !== null) {
      //return this.$http.get('http://localhost:8080/test/sensors_metrics.json')
      return this.$http.get("/customers/" + customerId + "/thermostats/" + thermostatId + "/sensors/metrics");
    }
  }

  triggerEvent(
    customerId: number,
    thermostatIds: (string | number)[],
    type: "restart" | "calibration" | "thermostatUpdate",
    updateRequest: {} | null = null
  ) {
    let urlForType = "";
    switch (type) {
      case "calibration":
        urlForType = "recalibrates";
        break;
      case "restart":
        urlForType = "restarts";
        break;
      case "thermostatUpdate":
        urlForType = "thermostat/update";
        break;
      default:
        return;
    }

    return this.$http.post("/customers/" + customerId + "/trigger/" + urlForType, {
      thermostatIds: thermostatIds,
      updateInfo: updateRequest,
    });
  }

  private getObjectBasicUrl(customerId: any, objectId: any): string | null {
    // Todo: figure out why it is NaN in the inital case
    if (customerId != null && !isNaN(customerId) && objectId != null && !isNaN(objectId))
      return "/customers/" + customerId + "/objects/" + objectId;
    else if (customerId != null && !isNaN(customerId) && objectId == null) {
      return "/customers/" + customerId + "/objects/" + customerId;
    } else return null;
  }

  private static buildQueryParametersString(options) {
    let extendedOptionsUrl = "";
    const entriesOfOptions = Object.entries(options);
    for (let i = 0; i < entriesOfOptions.length; i++) {
      extendedOptionsUrl += "&" + entriesOfOptions[i][0] + "=" + entriesOfOptions[i][1];
    }
    if (extendedOptionsUrl.length > 0) {
      extendedOptionsUrl = "?" + extendedOptionsUrl.slice(1);
    }
    return extendedOptionsUrl;
  }
}
