import axios from "axios";
import LocalStorage from "../helpers/LocalStorage";

interface LoginResult {
  url: string;
  success: boolean;
  token: string | null;
}

export default class ShardedLogin {
  private static _localStorageBackendUrlKey = "backend_url";
  private static _localStorageLoginResultsKey = "valid_login_results";

  private static _getBackendUrlsFromEnv(): string[] {
    const urls = window.__env__ ? window.__env__.VUE_APP_BACKEND_URL : process.env.VUE_APP_BACKEND_URL;
    if (urls) {
      // Regex matches list of comma-separated http(s)://.../api/
      const urlsRegex = /^https?:\/\/[0-9a-zA-Z.:-]+\/api\/(,https?:\/\/[0-9a-zA-Z.:-]+\/api\/)*$/;
      if (!urls.match(urlsRegex)) {
        console.error(
          `Format of VUE_APP_BACKEND_URL=${urls} should match ${urlsRegex} or as an example http://1.2.3.4:567/api/,https://other.url/api/`
        );
      }
      return urls.split(",").filter((url) => !!url);
    } else {
      console.error("Empty or no VUE_BACKEND_URL");
      return [];
    }
  }

  private static async _tryLoginToOneBackendShard(url: string, email: string, password: string): Promise<LoginResult> {
    try {
      // Make a login request to the Backend
      const response = await axios.post(url + "auth/login", { email, password });

      // Check the response for successful login
      if (response.status === 200) {
        return { url, success: true, token: response.data.token.replace(/^Bearer\s+/, "") };
      } else {
        return { url, success: false, token: null };
      }
    } catch (error) {
      return { url, success: false, token: null };
    }
  }

  private static async _tryLoginToMultipleURLs(
    urls: string[],
    email: string,
    password: string
  ): Promise<LoginResult[]> {
    const loginPromises = urls.map((url) => this._tryLoginToOneBackendShard(url, email, password));
    return await Promise.all(loginPromises);
  }

  public static async updateValidLoginResults(email: string, password: string): Promise<LoginResult[]> {
    LocalStorage.clear();
    const backendUrls = this._getBackendUrlsFromEnv();
    const loginResults = await this._tryLoginToMultipleURLs(backendUrls, email, password);
    const validLoginResults = loginResults.filter((login) => login.success);
    LocalStorage.save(this._localStorageLoginResultsKey, JSON.stringify(validLoginResults));
    return validLoginResults;
  }

  public static getStoredValidLoginResults(): LoginResult[] {
    const storedLoginResults = LocalStorage.get(ShardedLogin._localStorageLoginResultsKey);
    if (storedLoginResults !== null) {
      return JSON.parse(storedLoginResults);
    } else {
      throw "No login results were stored, make sure updateLoginResults is called during login";
    }
  }

  public static setBackendUrl(url: string) {
    // ToDo: Have to get the JWT as well and set it
    const loginResult = this.getStoredValidLoginResults().find((login) => login.url == url);
    if (loginResult) {
      LocalStorage.save(this._localStorageBackendUrlKey, url);
      LocalStorage.save("default_auth_token", loginResult.token);
    } else {
      console.error(`Trying to set backend URL to ${url} which is not part of the stored logins`);
    }
  }

  public static getBackendUrl(): string {
    return LocalStorage.get(this._localStorageBackendUrlKey) || "";
  }

  public static getBackendDefaultUrlFromFrontendUrl() {
    const host = window.location.hostname;
    switch (host) {
      case "dashboard.vilisto.net":
        return "//backend.vilisto.net/api";
      case "dashboard003.vilisto.net":
        return "//backend003.vilisto.net/api";
      case "sprinkenhof.vilisto.net":
        return "//backend-sprinkenhof.vilisto.net/api";
      case "staging-dashboard.vilisto.net":
        return "//staging-backend.vilisto.net/api";
      case "localhost":
        return "//127.0.0.1:3004/api";
      default:
        return "";
    }
  }
}
